import React, { FC, memo } from 'react';
import styles from './App.module.scss';
import noticeImg from '../assets/imgs/notice.png';

interface Props {}

const App: FC<Props> = memo(() => {
  return (
    <div className={styles.app}>
      <div className={styles.content}>
        <img src={noticeImg} alt="" />
      </div>
    </div>
  );
});

export default App;
